/* =======================
SlickNav
========================*/
$(function() {
    $('nav.main-nav').slicknav({
        prependTo: '.mobile-menu-anchor',
        closedSymbol: '',
        openedSymbol: ''
    }),
    $('.mobile-menu-toggle a').click(function() {
        $('nav.main-nav').slicknav("toggle");
        $('.mobile-menu-toggle .icon').toggleClass('icon-bars icon-times');
    })
})

/* =======================
Active Nav
========================*/
var path = location.pathname.substring(1);
if (path) { // if there is a value for the variable path

    /*-- for the primary nav - if path equals the href give the parent a class of selected
             example: nav ul li.selected a --*/
    $('nav a[href$="' + path + '"]').parent().addClass('selected');

    /*-- for dropdown nav - if path equals the href of the drop down links give the top level link a class of selected
             example: nav ul li.selected a --*/
    $('nav ul ul a[href$="' + path + '"]').parents(':eq(2)').addClass('selected');

    /*-- for the aside nav - if path equals the href give the parent a class of selected
             example: aside ul li.selected a --*/
    $('aside li a[href$="' + path + '"]').parent().addClass('selected');
};

/* =======================
Accordion
========================*/
$(function(){
    $('.accordion dd').css('display','none');
    var link = $('.accordion dt > a');

    link.on('click', function(e) {

        e.preventDefault();
        var a = $(this).parent();

        $('.accordion dd').not(a).slideUp('300');

        if ($(a).hasClass('active')) {
            $(a).next('dd').slideUp('300').prev('dt').removeClass();
        }
        else {
            $('.accordion dt').removeClass();
            $(a).next('dd').slideDown('300').prev('dt').addClass('active');
        }
    });
});

/* =======================
Document Ready Scripts
========================*/
$(document).ready(function(){

    $('.image-link').magnificPopup({type:'image'});
    $('.video-link').magnificPopup({type:'iframe'});

    $('.image-gallery').each(function(index , value){
        var gallery = $(this);
        var galleryImages = $(this).data('links').split(',');
        var items = [];
        for(var i=0;i<galleryImages.length; i++){
          items.push({
            src:galleryImages[i],
            title:''
          });
        }
        gallery.magnificPopup({
          mainClass: 'mfp-fade',
          items:items,
          gallery:{
            enabled:true,
            tPrev: $(this).data('prev-text'),
            tNext: $(this).data('next-text')
          },
          type: 'image'
        });
    });

    $('.contact-header .contact-toggle').click(function(){
        $(this).toggleClass('open');
        $(this).children('.icon').toggleClass('icon-chevron-down icon-times');
        $('.contact-header-wrap').toggleClass('open');
    });

});